import localFont from 'next/font/local'

export const Pretendard = localFont({
  variable: '--font-Pretendard',
  src: [
    {
      path: './Pretendard/PretendardVariable.woff2',
    },
  ],
})

export const DespairTime = localFont({
  variable: '--font-DespairTime',
  src: [
    {
      path: './DespairTime/Despair_Time_Straight.otf',
      style: 'normal',
    },
  ],
})
