import Head from 'next/head'
import type { AppProps } from 'next/app'
import Script from 'next/script'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import gtag from '@/libs/ga4/gtag'

import * as Font from '@/assets/fonts'

import '@/styles/globals.css'
import clsx from 'clsx'

const GA4_ID = process.env.NEXT_PUBLIC_GA_ID

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (path: string) => {
      gtag.pageview({ path })
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA4_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${GA4_ID}');
    `}
      </Script>
      <Head>
        <title>ONOB</title>
        <meta
          name="description"
          content="가치를 발견하는 특별한 경험, 오노브 플리마켓"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, viewport-fit=cover"
        />
        <link rel="icon" href="/favicon.ico" />

        <meta property="og:title" content="On aNd Off Brand, ONOB" />
        <meta
          property="og:image"
          content="https://fleabuddy.s3.ap-northeast-2.amazonaws.com/static/images/og_default_thumbnail_v2.png"
        />

        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="가치를 발견하는 특별한 경험, 오노브 플리마켓"
        />

        <meta name="twitter:title" content="On aNd Off Brand, ONOB" />
        <meta
          name="twitter:description"
          content="가치를 발견하는 특별한 경험, 오노브 플리마켓"
        />
        <meta
          name="twitter:image"
          content="https://fleabuddy.s3.ap-northeast-2.amazonaws.com/static/images/og_default_thumbnail.jpg "
        />
      </Head>

      <div
        className={clsx(
          `${Object.values(Font)
            .map((font) => font.variable)
            .join(' ')}`,
          'bg-black',
          'text-white',
          'font-Pretendard',
          'text-base',
        )}
      >
        <Component {...pageProps} />
        <div id="_outer"></div>
        <div id="_modal"></div>
      </div>
    </>
  )
}
