export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
interface PaveViewParameters {
  path: string
  title?: string
  location?: string
}
const pageview = (params: PaveViewParameters) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: params.path,
    paga_title: params.title,
    page_location: params.location,
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
interface EventParms {
  action: string
  category: string
  label: string
  value: string
}
const event = ({ action, category, label, value }: EventParms) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}

const gtag = {
  pageview,
  event,
}
export default gtag
